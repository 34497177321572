import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px;
  * {
    font-family: sofia-pro, sans-serif;
  }
  h5 {
    font-size: 18px;
  }
`;