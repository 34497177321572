import React from 'react';
//components
import Layout from '../components/Layout';
import PrivacyPolicyDesktop from '../components/PrivacyPolicy';
//helpers
//styles
import './style.css';

function PrivacyPolicy() {
  return (
    <Layout
      mobile={<PrivacyPolicyDesktop />}
      desktop={<PrivacyPolicyDesktop />} />
  );
}

export default PrivacyPolicy;